import DeviceApiProvider from "@/services/DeviceApiProvider";
import Helpers from "@/utils/Helpers";

const device_api_provider = new DeviceApiProvider();
device_api_provider.setAuthorizationHeadersFromLocal();

const getDefaultState = () => {
  let create_devices = [];
  let device_obj = getDefaultDeviceObject();
  create_devices.push(device_obj);

  return {
    device_list: [],
    total_devices: 0,
    total_pages: 0,
    current_page: 0,
    is_next_page: false,
    filter_page: null,
    filter_limit: 20,
    filter_search: null,
    filter_siteId: null,
    create_devices,
    patch_device: {},
    is_display_create_dialog: false,
  };
};

const getDefaultDeviceObject = () => {
  return {
    type: null,
    name: null,
    brand: null,
    model: null,
    variables: null,
    advance: null,
    status: "pending",
  };
};

export default {
  state: getDefaultState(),
  getters: {
    device_list_filters(state) {
      let filters = {};
      if (state.filter_page) {
        filters["page"] = state.filter_page;
      }

      if (state.filter_limit) {
        filters["limit"] = state.filter_limit;
      }

      if (state.filter_search) {
        filters["search"] = state.filter_search;
      }

      if (state.filter_siteId) {
        filters["siteId"] = state.filter_siteId;
      }

      return filters;
    },
  },
  actions: {
    async requestGetDeviceList({ commit, getters }) {
      commit("DISPLAY_LOADING");
      try {
        const response = await device_api_provider.requestGetDeviceList(
          getters.device_list_filters
        );
        if (response.pagination.page == 0) {
          commit("SET_DEVICES", response.data);
        } else {
          commit("APPEND_DEVICES", response.data);
        }
        commit("SET_DEVICES_PAGINATION", response.pagination);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestGetDeviceModelList({ commit }, device_type) {
      commit("DISPLAY_LOADING");
      try {
        const response = await device_api_provider.requestGetDeviceModelList(
          device_type
        );
        commit("HIDE_LOADING");
        return Helpers.setBrandAndModelFromResponseObject(response.models);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestGetDeviceModelVariableList({ commit }, params) {
      commit("DISPLAY_LOADING");
      try {
        const response =
          await device_api_provider.requestGetDeviceModelVariableList(params);

        if (params.edit_mode) {
          commit("SET_PATCH_DEVICE_OBJECT_FIELD", {
            field: "advance",
            value: response.advance,
          });
          commit("SET_PATCH_DEVICE_OBJECT_FIELD", {
            field: "variables",
            value: response.variable,
          });
        } else {
          commit("SET_CREATE_DEVICE_OBJECT", {
            sequence: params.sequence,
            field: "advance",
            value: response.advance,
          });
          commit("SET_CREATE_DEVICE_OBJECT", {
            sequence: params.sequence,
            field: "variables",
            value: response.variable,
          });
        }
        commit("HIDE_LOADING");
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestCreateNewDevice({ commit }, params) {
      try {
        const response = await device_api_provider.requestCreateNewDevice(
          params
        );
        commit("SET_SUCCESS_CREATE_DEVICE_STATUS", response.device);
      } catch (error) {
        commit("SET_ERROR_CREATE_DEVICE_STATUS", { params, error });
      }
    },
    async requestGetDeviceDetailByID({ commit }, device_id) {
      commit("DISPLAY_LOADING");
      try {
        const response = await device_api_provider.requestGetDeviceDetailByID(
          device_id
        );
        commit("HIDE_LOADING");

        return response;
      } catch (error) {
        commit("HIDE_LOADING");
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async requestDeleteDeviceByID({ commit }, device_id) {
      commit("DISPLAY_LOADING");
      try {
        const response = await device_api_provider.requestDeleteDeviceByID(
          device_id
        );
        commit("HIDE_LOADING");
        const text = "The device was successfully removed from  system!";
        commit("DISPLAY_SUCCESS_DIALOG", text);
        return response;
      } catch (error) {
        commit("HIDE_LOADING");
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
  },
  mutations: {
    SET_DEVICES(state, payload) {
      state.device_list = payload;
    },
    APPEND_DEVICES(state, payload) {
      state.device_list = state.device_list.concat(payload);
    },
    SET_PATCH_DEVICE_OBJECT(state, payload) {
      state.patch_device = payload;
    },
    SET_PATCH_DEVICE_OBJECT_FIELD(state, payload) {
      state.patch_device[payload.field] = payload.value;
    },
    SET_CREATE_DEVICE_OBJECT(state, payload) {
      const index = payload.sequence - 1;
      state.create_devices[index][payload.field] = payload.value;
    },
    APPEND_CREATE_DEVICE_OBJECT(state) {
      // const last_obj = state.create_devices.slice(-1);
      // const clone_obj = Object.assign({}, last_obj[0]);
      const clone_obj = getDefaultDeviceObject();
      state.create_devices.push(clone_obj);
    },
    REMOVE_CREATE_DEVICE_OBJECT(state, payload) {
      const index = payload - 1;
      state.create_devices.splice(index, 1);
    },
    SET_SUCCESS_CREATE_DEVICE_STATUS(state, payload) {
      const index = state.create_devices.findIndex(
        (item) => item.name === payload.deviceName
      );
      if (index >= 0) {
        state.create_devices[index].status = "success";
      }
    },
    SET_ERROR_CREATE_DEVICE_STATUS(state, payload) {
      const index = state.create_devices.findIndex(
        (item) => item.name === payload.params.deviceName
      );
      if (index >= 0) {
        state.create_devices[index].status = "fail";
      }
    },
    SET_DEVICE_FILTER_LIMIT(state, payload) {
      state.filter_limit = payload;
    },
    SET_DEVICE_FILTER_PAGE(state, payload) {
      state.filter_page = payload;
    },
    SET_DEVICE_FILTER_SEARCH_TEXT(state, payload) {
      state.filter_search = payload;
    },
    SET_DEVICE_FILTER_SITE_ID(state, payload) {
      state.filter_siteId = payload;
    },
    SET_DEVICES_PAGINATION(state, payload) {
      state.total_devices = payload.count;
      state.total_pages = payload.total;
      state.current_page = payload.page;
      if (payload.page !== payload.total - 1) {
        state.is_next_page = payload.count > payload.limit;
      } else {
        state.is_next_page = false;
      }
    },
    DISPLAY_CREATE_DEVICES_DIALOG(state) {
      state.is_display_create_dialog = true;
    },
    HIDE_CREATE_DEVICES_DIALOG(state) {
      state.is_display_create_dialog = false;
    },
    RESTATE_CREATE_DEVICE(state) {
      const device_obj = getDefaultDeviceObject();
      state.create_devices = [];
      state.create_devices.push(device_obj);
    },
    RESET_DEVICE_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
