import EdgeApiProvider from "@/services/EdgeApiProvider";

const edge_api_provider = new EdgeApiProvider();
edge_api_provider.setAuthorizationHeadersFromLocal();

const getDefaultState = () => {
  let edge_modules = [];
  let edge_module_obj = getDefaultModuleObject();
  edge_modules.push(edge_module_obj);

  return {
    edge_list: [],
    total_edges: 0,
    total_pages: 0,
    current_page: 0,
    is_next_page: false,
    filter_page: null,
    filter_limit: 20,
    filter_search: null,
    filter_siteId: null,
    edge_modules,
  };
};

const getDefaultModuleObject = () => {
  return {
    status: "pending",
    name: "modbus",
    type: "modbus",
    architect: "arm-64",
    version: "7763",
    registry: "bpnsgpdiotedgecontainerregistry.azurecr.io",
    serialPort: ["/dev/ttyS0"],
    enableLogging: false,
    deviceIds: ["62c298c999e9a4001262fc9a"],
  };
};

export default {
  state: getDefaultState(),
  getters: {
    edge_list_filters(state) {
      let filters = {};
      if (state.filter_page) {
        filters["page"] = state.filter_page;
      }

      if (state.filter_limit) {
        filters["limit"] = state.filter_limit;
      }

      if (state.filter_search) {
        filters["search"] = state.filter_search;
      }

      if (state.filter_siteId) {
        filters["siteId"] = state.filter_siteId;
      }

      return filters;
    },
  },
  actions: {
    async requestGetIotEdgeList({ commit, getters }) {
      commit("DISPLAY_LOADING");
      try {
        const response = await edge_api_provider.requestGetIotEdgeList(
          getters.edge_list_filters
        );
        if (response.pagination.page == 0) {
          commit("SET_EDGES", response.data);
        } else {
          commit("APPEND_EDGES", response.data);
        }
        commit("SET_EDGE_PAGINATION", response.pagination);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestCreateNewIotEdge({ commit }, params) {
      try {
        const response = await edge_api_provider.requestCreateNewIotEdge(
          params
        );
        const text =
          response.iotedge.edgeName +
          "was successfully created!! with connection string: " +
          response.connectionString +
          " ) ";
        commit("DISPLAY_SUCCESS_DIALOG", text);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async requestGetIotEdgeDetailByID({ commit }, edge_id) {
      commit("DISPLAY_LOADING");
      try {
        const response = await edge_api_provider.requestGetIotEdgeDetailByID(
          edge_id
        );
        commit("HIDE_LOADING");
        commit("SET_EDGE_MODULES", response.modules);

        return response;
      } catch (error) {
        commit("HIDE_LOADING");
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
  },
  mutations: {
    SET_EDGES(state, payload) {
      state.edge_list = payload;
    },
    APPEND_EDGES(state, payload) {
      state.edge_list = state.edge_list.concat(payload);
    },
    SET_EDGE_FILTER_LIMIT(state, payload) {
      state.filter_limit = payload;
    },
    SET_EDGE_FILTER_PAGE(state, payload) {
      state.filter_page = payload;
    },
    SET_EDGE_FILTER_SEARCH_TEXT(state, payload) {
      state.filter_search = payload;
    },
    SET_EDGE_FILTER_SITE_ID(state, payload) {
      state.filter_siteId = payload;
    },
    SET_EDGE_PAGINATION(state, payload) {
      state.total_edges = payload.count;
      state.total_pages = payload.total;
      state.current_page = payload.page;
      if (payload.page !== payload.total - 1) {
        state.is_next_page = payload.count > payload.limit;
      } else {
        state.is_next_page = false;
      }
    },
    SET_EDGE_MODULES(state, payload) {
      state.edge_modules = payload;
    },
    SET_CREATE_MODULE_OBJECT(state, payload) {
      const index = payload.sequence - 1;
      state.edge_modules[index][payload.field] = payload.value;
    },
    APPEND_CREATE_MODULE_OBJECT(state) {
      const clone_obj = getDefaultModuleObject();
      state.edge_modules.push(clone_obj);
    },
    REMOVE_CREATE_MODULE_OBJECT(state, payload) {
      const index = payload - 1;
      state.edge_modules.splice(index - 1, 1);
    },
    SET_SUCCESS_CREATE_EDGE_STATUS(state, payload) {
      const index = state.edge_modules.findIndex(
        (item) => item.name === payload.deviceName
      );
      if (index >= 0) {
        state.edge_modules[index].status = "success";
      }
    },
    SET_ERROR_CREATE_EDGE_STATUS(state, payload) {
      const index = state.edge_modules.findIndex(
        (item) => item.name === payload.params.deviceName
      );
      if (index >= 0) {
        state.edge_modules[index].status = "fail";
      }
    },
    RESET_EDGE_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
