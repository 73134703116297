import HttpRequest from "./HttpRequest";
import Helpers from "@/utils/Helpers";

class HubApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetHubList(filters) {
    let query_string = Helpers.generateQueryString(filters);
    const request_url = "/admin/iothubs" + query_string;
    const data = await this.get(request_url);
    return data;
  }
}

export default HubApiProvider;
