import HttpRequest from "./HttpRequest";
import Helpers from "@/utils/Helpers";

class SiteApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetSiteList(filters) {
    let query_string = Helpers.generateQueryString(filters);
    const request_url = "/admin/sites" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  async requestCreateNewSite(params) {
    const request_url = "/admin/sites";
    const data = await this.create(request_url, params);
    return data;
  }

  async requestGetSiteDetailByID(site_id) {
    const request_url = "/admin/sites/" + site_id;
    const data = await this.get(request_url);
    return data;
  }

  async requestRemoveExistingSite(site_id) {
    const request_url = "/admin/sites/" + site_id;
    const data = await this.delete(request_url);
    return data;
  }
}

export default SiteApiProvider;
