import HttpRequest from "./HttpRequest";
import Helpers from "@/utils/Helpers";

class EdgeApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetIotEdgeList(filters) {
    let query_string = Helpers.generateQueryString(filters);
    const request_url = "/admin/iotedges" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  async requestCreateNewIotEdge(params) {
    const request_url = "/admin/iotedges";
    const data = await this.create(request_url, params);
    return data;
  }

  async requestGetIotEdgeDetailByID(edge_id) {
    const request_url = "/admin/iotedges/" + edge_id;
    const data = await this.get(request_url);
    return data;
  }
}

export default EdgeApiProvider;
