<template>
  <v-app>
    <router-view />
    <Loading />
    <ErrorDialog />
    <DeleteDialog />
    <SuccessDialog />
  </v-app>
</template>

<script>
import Loading from "@/components/Loading";
import ErrorDialog from "@/components/dialogs/ErrorDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import SuccessDialog from "@/components/dialogs/SuccessDialog";

export default {
  name: "App",
  components: {
    Loading,
    ErrorDialog,
    DeleteDialog,
    SuccessDialog,
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
