import SiteApiProvider from "@/services/SiteApiProvider";

const site_api_provider = new SiteApiProvider();
site_api_provider.setAuthorizationHeadersFromLocal();

const getDefaultState = () => {
  return {
    site_list: [],
    total_sites: 0,
    total_page: 0,
    current_page: 0,
    is_next_page: false,
    filter_page: null,
    filter_limit: 20,
  };
};

export default {
  state: getDefaultState(),
  getters: {
    site_list_filters(state) {
      let filters = {};
      if (state.filter_page) {
        filters["page"] = state.filter_page;
      }

      if (state.filter_limit) {
        filters["limit"] = state.filter_limit;
      }

      return filters;
    },
  },
  actions: {
    async requestGetSiteList({ commit, getters }) {
      commit("DISPLAY_LOADING");
      try {
        const response = await site_api_provider.requestGetSiteList(
          getters.site_list_filters
        );
        if (response.pagination.page == 0) {
          commit("SET_SITES", response.data);
        } else {
          commit("APPEND_SITES", response.data);
        }
        commit("SET_SITES_PAGINATION", response.pagination);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestCreateNewSite({ commit }, params) {
      commit("DISPLAY_LOADING");
      try {
        const response = await site_api_provider.requestCreateNewSite(params);
        const text =
          response.siteName +
          "( " +
          response.code +
          " ) was successfully created!!";
        commit("DISPLAY_SUCCESS_DIALOG", text);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestGetSiteDetailByID({ commit }, site_id) {
      commit("DISPLAY_LOADING");
      try {
        const response = await site_api_provider.requestGetSiteDetailByID(
          site_id
        );
        commit("HIDE_LOADING");
        return response;
      } catch (error) {
        commit("HIDE_LOADING");
        commit("DISPLAY_ERROR_DIALOG", error);
      }
    },
    async requestRemoveExistingSite({ commit }, site_id) {
      commit("DISPLAY_LOADING");
      try {
        const response = await site_api_provider.requestRemoveExistingSite(
          site_id
        );
        console.log(response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
  },
  mutations: {
    SET_SITES(state, payload) {
      state.site_list = payload;
    },
    APPEND_SITES(state, payload) {
      state.site_list = state.site_list.concat(payload);
    },
    SET_SITE_FILTER_LIMIT(state, payload) {
      state.filter_limit = payload;
    },
    SET_SITE_FILTER_PAGE(state, payload) {
      state.filter_page = payload;
    },
    SET_SITES_PAGINATION(state, payload) {
      state.total_sites = payload.count;
      state.total_page = payload.total;
      state.current_page = payload.page;
      if (payload.page !== payload.total - 1) {
        state.is_next_page = payload.count > payload.limit;
      } else {
        state.is_next_page = false;
      }
    },
    RESET_SITE_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
