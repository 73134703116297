import Helpers from "@/utils/Helpers";

export default [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      Helpers.isTokenExist() ? next() : next("login");
    },
    component: () =>
      import(
        /* webpackChunkName: "navbar-layout" */ "@/layout/NavbarLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
      },
      {
        path: "/sites",
        name: "site",
        component: () =>
          import(/* webpackChunkName: "sites" */ "@/views/site/SiteView.vue"),
      },
      {
        path: "/sites/create",
        name: "site-create",
        component: () =>
          import(
            /* webpackChunkName: "site-create" */ "@/views/site/SiteCreateView.vue"
          ),
      },
      {
        path: "/sites/detail/:id",
        name: "site-detail",
        component: () =>
          import(
            /* webpackChunkName: "site-detail" */ "@/views/site/SiteDetailView.vue"
          ),
      },
      {
        path: "/devices",
        name: "device",
        component: () =>
          import(
            /* webpackChunkName: "devices" */ "@/views/device/DeviceView.vue"
          ),
      },
      {
        path: "/devices/create",
        name: "device-create",
        component: () =>
          import(
            /* webpackChunkName: "device-create" */ "@/views/device/DeviceCreateView.vue"
          ),
      },
      {
        path: "/devices/detail/:id",
        name: "device-detail",
        component: () =>
          import(
            /* webpackChunkName: "device-detail" */ "@/views/device/DeviceDetailView.vue"
          ),
      },
      {
        path: "/edges",
        name: "edge",
        component: () =>
          import(
            /* webpackChunkName: "edges" */ "@/views/edge/IotEdgeView.vue"
          ),
      },
      {
        path: "/edges/create",
        name: "edge-create",
        component: () =>
          import(
            /* webpackChunkName: "edge-create" */ "@/views/edge/IotEdgeCreateView.vue"
          ),
      },
      {
        path: "/edges/detail/:id",
        name: "edge-detail",
        component: () =>
          import(
            /* webpackChunkName: "edge-detail" */ "@/views/edge/IotEdgeDetailView.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/auth/LoginView.vue"),
  },
  {
    path: "/auth/redirect",
    name: "aad_redirect",
    component: () =>
      import(
        /* webpackChunkName: "aad_redirect" */ "@/views/auth/AadRedirectView.vue"
      ),
  },
  {
    path: "*",
    name: "page-not-found",
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ "@/views/NotFoundView.vue"
      ),
  },
];
