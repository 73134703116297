import HttpRequest from "./HttpRequest";
import Helpers from "@/utils/Helpers";

class DeviceApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetDeviceList(filters) {
    let query_string = Helpers.generateQueryString(filters);
    const request_url = "/admin/devices" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  async requestGetDeviceModelList(device_type) {
    const request_url = "/admin/devices/models?deviceType=" + device_type;
    const data = await this.get(request_url);
    return data;
  }

  async requestGetDeviceModelVariableList(params) {
    const request_url =
      "/admin/devices/models/" + params.type + "/" + params.brand_model;
    const data = await this.get(request_url);
    return data;
  }

  async requestCreateNewDevice(params) {
    const request_url = "/admin/devices";
    const data = await this.create(request_url, params);
    return data;
  }

  async requestGetDeviceDetailByID(device_id) {
    const request_url = "/admin/devices/" + device_id;
    const data = await this.get(request_url);
    return data;
  }

  async requestDeleteDeviceByID(device_id) {
    const request_url = "/admin/devices/" + device_id;
    const data = await this.delete(request_url);
    return data;
  }
}

export default DeviceApiProvider;
