import store from "@/store";

export default {
  isTokenExist: function () {
    const token = store.state.access_token;
    return token && token != "";
  },
  generateQueryString: function (filter_object) {
    // Check is Filter objet != null or undefined
    if (filter_object) {
      const filter_keys = Object.keys(filter_object);
      // Check is Filter objet is not empty
      if (filter_keys.length > 0) {
        const query_string =
          "?" +
          filter_keys.map((key) => key + "=" + filter_object[key]).join("&");
        return query_string;
      }
    }
    return "";
  },
  setBrandAndModelFromResponseObject: function (res_object) {
    let models = [];
    const brands = Object.keys(res_object);
    for (const [key, values] of Object.entries(res_object)) {
      const model_formatter = values.map((model) => {
        return { brand: key, model };
      });
      models = models.concat(model_formatter);
    }
    return {
      brands,
      models,
    };
  },
};
