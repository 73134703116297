<template>
  <v-overlay id="SuccessDialog" :opacity="0.3" :value="is_success">
    <v-dialog v-model="is_success" persistent max-width="380">
      <v-card class="text-center mx-auto" flat rounded="lg">
        <v-card-title class="primary--text font-weight-bold justify-center">
          Operation Success
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4">
          <v-icon x-large color="light-green">mdi-check-circle</v-icon>
        </v-card-text>
        <v-card-text>
          <span class="font-weight-medium">
            {{ success_msg ? success_msg : "-" }}</span
          >
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            rounded
            depressed
            color="primary"
            @click.prevent="dismissDialog()"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "SuccessDialog",
  computed: {
    ...mapState({
      is_success: (state) => state.is_success,
      success_msg: (state) => state.success_msg,
    }),
  },
  methods: {
    ...mapMutations({
      hideSuccessDialog: "HIDE_SUCCESS_DIALOG",
    }),
    dismissDialog() {
      this.hideSuccessDialog();
      const paths = this.$route.path.split("/");
      if (paths.length > 2) {
        this.$router.replace({ name: paths[1] });
      }
    },
  },
};
</script>

<style></style>
