<template>
  <v-overlay id="Loading" :opacity="0.3" :value="is_loading">
    <v-progress-circular indeterminate size="64" />
    <p class="mt-2 medium">Loading...</p>
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Loading",
  computed: {
    ...mapState({
      is_loading: (state) => state.is_loading,
    }),
  },
};
</script>

<style></style>
