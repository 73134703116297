import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import AuthApiProvider from "@/services/AuthApiProvider";
const auth_api_provider = new AuthApiProvider();

// Import : Modules
import hub from "./modules/hub";
import site from "./modules/site";
import device from "./modules/device";
import edge from "./modules/edge";

// Setup : VuexPersistence
const vuexLocal = new VuexPersistence({
  key: "nixt_admin_storage",
  storage: window.localStorage,
  reducer: (state) => ({ access_token: state.access_token }),
});

export default new Vuex.Store({
  state: {
    user_profile: null,
    access_token: null,
    is_loading: false,
    is_success: false,
    success_msg: null,
    is_error: false,
    error_msg: null,
    is_delete: false,
    delete_item: null,
  },
  actions: {
    async redirectToActiveDirectoryUrl({ commit }) {
      commit("DISPLAY_LOADING");
      try {
        const response = await auth_api_provider.requestGetActiveDirectoryUrl();
        window.location.replace(response.url);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestCreateAccessToken({ commit }, aad_code) {
      commit("DISPLAY_LOADING");
      try {
        const response = await auth_api_provider.requestCreateAccessToken({
          code: aad_code,
        });
        commit("SET_ACCESS_TOKEN", response.token);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
    async requestVerifyAccessToken({ commit }) {
      commit("DISPLAY_LOADING");
      auth_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await auth_api_provider.requestVerifyAccessToken();
        commit("SET_USER_PROFILE", response);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.access_token = payload;
    },
    SET_USER_PROFILE(state, payload) {
      state.user_profile = payload;
    },
    DISPLAY_LOADING(state) {
      state.is_loading = true;
    },
    HIDE_LOADING(state) {
      state.is_loading = false;
    },
    DISPLAY_ERROR_DIALOG(state, payload) {
      state.is_error = true;
      state.error_msg = payload;
    },
    HIDE_ERROR_DIALOG(state) {
      state.is_error = false;
    },
    DISPLAY_SUCCESS_DIALOG(state, payload) {
      state.is_success = true;
      state.success_msg = payload;
    },
    HIDE_SUCCESS_DIALOG(state) {
      state.is_success = false;
    },
    DISPLAY_DELETE_DIALOG(state, payload) {
      state.is_delete = true;
      state.delete_item = payload;
    },
    HIDE_DELETE_DIALOG(state) {
      state.is_delete = false;
      state.delete_item = null;
    },
  },
  modules: { hub, site, device, edge },
  plugins: [vuexLocal.plugin],
});
