<template>
  <v-overlay id="ErrorDialog" :opacity="0.3" :value="is_delete">
    <v-dialog v-model="is_delete" persistent max-width="380">
      <v-card v-if="delete_item" class="text-center mx-auto" flat rounded="lg">
        <v-card-title
          class="red--text text--darken-1 font-weight-bold justify-center"
        >
          Perform Delete Action
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4">
          <span>
            Are you sure to permanently remove device
            <b> {{ delete_item.name }}</b> from the system?</span
          >
        </v-card-text>
        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center py-3">
          <v-btn
            rounded
            outlined
            depressed
            color="grey"
            class="px-4"
            @click.prevent="hideDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            rounded
            depressed
            color="red"
            class="px-4"
            @click.prevent="performDeleteAction()"
          >
            <span class="white--text">Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "ErrorDialog",
  computed: {
    ...mapState({
      is_delete: (state) => state.is_delete,
      delete_item: (state) => state.delete_item,
    }),
  },
  methods: {
    ...mapMutations({
      hideDialog: "HIDE_DELETE_DIALOG",
    }),
    ...mapActions({
      requestDeleteDeviceByID: "requestDeleteDeviceByID",
    }),
    async performDeleteAction() {
      const result = await this.requestDeleteDeviceByID(this.delete_item.id);
      this.hideDialog();
      if (result) {
        this.$router.replace({ name: "device" });
      }
    },
  },
};
</script>

<style></style>
