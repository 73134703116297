import HubApiProvider from "@/services/HubApiProvider";

const hub_api_provider = new HubApiProvider();
hub_api_provider.setAuthorizationHeadersFromLocal();

export default {
  state: {
    hub_list: [],
    total_hubs: 0,
    total_pages: 0,
    current_page: 0,
    is_next_page: false,
    filter_page: null,
    filter_limit: 20,
  },
  getters: {
    hub_list_filters(state) {
      let filters = {};
      if (state.filter_page) {
        filters["page"] = state.filter_page;
      }

      if (state.filter_limit) {
        filters["limit"] = state.filter_limit;
      }

      return filters;
    },
  },
  actions: {
    async requestGetHubList({ commit, getters }) {
      commit("DISPLAY_LOADING");
      try {
        const response = await hub_api_provider.requestGetHubList(
          getters.hub_list_filters
        );
        if (response.pagination.page == 0) {
          commit("SET_HUBS", response.data);
        } else {
          commit("APPEND_HUBS", response.data);
        }
        commit("SET_HUBS_PAGINATION", response.pagination);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("HIDE_LOADING");
    },
  },
  mutations: {
    SET_HUBS(state, payload) {
      state.hub_list = payload;
    },
    APPEND_HUBS(state, payload) {
      state.hub_list = state.hub_list.concat(payload);
    },
    SET_HUB_FILTER_LIMIT(state, payload) {
      state.filter_limit = payload;
    },
    SET_HUB_FILTER_PAGE(state, payload) {
      state.filter_page = payload;
    },
    SET_HUBS_PAGINATION(state, payload) {
      state.total_hubs = payload.count;
      state.total_pages = payload.total;
      state.current_page = payload.page;
      if (payload.page !== payload.total - 1) {
        state.is_next_page = payload.count > payload.limit;
      } else {
        state.is_next_page = false;
      }
    },
  },
};
