import HttpRequest from "./HttpRequest";

class AuthApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetActiveDirectoryUrl() {
    const request_url = "/aad/login";
    const data = await this.get(request_url);
    return data;
  }

  async requestCreateAccessToken(params) {
    const request_url = "/aad/token";
    const data = await this.create(request_url, params);
    return data;
  }

  async requestVerifyAccessToken() {
    const request_url = "/admin/auth/verify";
    const data = await this.get(request_url);
    return data;
  }
}

export default AuthApiProvider;
